import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import "./index.css";
import i18n from "./i18n";
import { UserContextProvider } from "./store/userContext";
import { RouterContextProvider } from "./store/routerContext";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core
import "primeicons/primeicons.css"; //icons
import { AnimationContextProvider } from "./store/AnimationContext";
import "animate.css";
import { store } from "./store/store";
import App from "./App";

createRoot(document.getElementById("root")).render(
  <UserContextProvider>
    <RouterContextProvider>
      <AnimationContextProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Provider>
      </AnimationContextProvider>
    </RouterContextProvider>
  </UserContextProvider>
);
