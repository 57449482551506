/**
 * Ce fichier regroupe les fonctions associés au sessionStorage
 * Pour chaque item, on a 3 fonctions :
 *  - récupérer l'item
 *  - modifier la valeur de l'item
 *  - supprimer l'item
 */

const tokenItem = "bookitToken";
//const firstnameItem = "bookitFirstname";
//const lastnameItem = "bookitLastname";

export const getToken = () => {
  return sessionStorage.getItem(tokenItem);
};
export const setToken = (token: any) => {
  return sessionStorage.setItem(tokenItem, token);
};
export const removeToken = () => {
  return sessionStorage.removeItem(tokenItem);
};

// export const getFirstname = () => {
//   return sessionStorage.getItem(firstnameItem);
// };
// export const setFirstname = (firstname) => {
//   return sessionStorage.setItem(firstnameItem, firstname);
// };
// export const removeFirstname = () => {
//   return sessionStorage.removeItem(firstnameItem);
// };

// export const getLastname = () => {
//   return sessionStorage.getItem(lastnameItem);
// };
// export const setLastname = (lastname) => {
//   return sessionStorage.setItem(lastnameItem, lastname);
// };
// export const removeLastname = () => {
//   return sessionStorage.removeItem(lastnameItem);
// };

//Vider le sessionStorage
//en conservant la langue
export const clearStorage = () => {
  const lng = sessionStorage.getItem("i18nextLng");
  sessionStorage.clear();
  if (lng) {
    sessionStorage.setItem("i18nextLng", lng);
  }
};
