import React, { useContext } from "react";
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockLocked, cilUser, cilSettings } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import style from "./AppHeaderDropdown.module.css";
import { backdropShowActions } from "src/store/features/backdrop/backdropShowSlice";
import { UserContext } from "src/store/userContext";
import { RouterContext } from "src/store/routerContext";
import { getAllowedRoutes } from "src/utils/routes/getAllowedRoutes";
import { routes } from "src/utils/routes/routes";
import AvatarInitial from "src/components/avatar/AvatarInital";
import { RootState } from "src/store/store";

const AppHeaderDropdown = () => {
  const { t } = useTranslation(["headerDropdown"]);

  const navigate = useNavigate();
  const { handleUpdateRouter } = useContext(RouterContext);
  const { logout } = useContext(UserContext);

  const handlelogoutL = () => {
    //manageMercureSubscriptions([])
    logout();
    handleUpdateRouter(getAllowedRoutes(routes));
    //handleLogout()
    navigate("/login");
    //window.location.replace(window.location.origin + "/login")
  };

  const handleClick = () => {
    navigate("/profile");
  };

  //Ajout d'un effet de blur au clique sur le dropdown en récupérant l'état du backdrop dans le store
  const dispatch = useDispatch();
  const backdropShow = useSelector(
    (state: RootState) => state.backdropShow.backdropShow
  );

  return (
    <CDropdown
      variant="nav-item"
      onClick={() => dispatch(backdropShowActions.show(!backdropShow))}
    >
      <CDropdownToggle className={`py-0`} caret={false}>
        <AvatarInitial />
      </CDropdownToggle>
      <CDropdownMenu className={`${style.dropdown} dropdown-menu`}>
        <CDropdownItem>
          <div
            style={{ cursor: "pointer" }}
            className="px-0"
            onClick={handleClick}
          >
            <CIcon icon={cilUser} className="me-2" />
            {t("profile")}
          </div>
        </CDropdownItem>
        <CDropdownItem>
          <div
            style={{ cursor: "pointer" }}
            className="px-0"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <CIcon icon={cilSettings} className="me-2" />
            {t("settings")}
          </div>
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem>
          <div style={{ cursor: "pointer" }} onClick={handlelogoutL}>
            <CIcon icon={cilLockLocked} className="me-2" />
            {t("logout")}
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
