import jwt from "jwt-decode";
import { redirect } from "react-router-dom";
import { getToken } from "./browserStorage";
import { TokenInterface } from "src/typesPerso/TokenInterface";

//Retourne la valeur du user dans le session storage
export const getUser = () => {
  return sessionStorage.getItem("user");
};

//Modifie la valeur du user dans le session storage
export const setUser = (user: any) => {
  sessionStorage.setItem("user", user);
};

//Retourne le prénom de l'utilisateur dans le session storage
export const getFirstname = () => {
  if (getUser()) {
    return JSON.parse(sessionStorage.user).firstname;
  } else {
    return null;
  }
};

//Modifie le prénom de l'utilisateur dans le session storage
export const setFirstname = (firstname: string) => {
  if (getUser()) {
    const user = JSON.parse(sessionStorage.user);
    user.firstname = firstname;
    sessionStorage.setItem("user", JSON.stringify(user));
  }
};

//Retourne le nom de l'utilisateur dans le session storage
export const getLastname = () => {
  if (getUser()) {
    return JSON.parse(sessionStorage.user).lastname;
  } else {
    return null;
  }
};

//Modifie le nom de l'utilisateur dans le session storage
export const setLastname = (lastname: string) => {
  if (getUser()) {
    const user = JSON.parse(sessionStorage.user);
    user.lastname = lastname;
    sessionStorage.setItem("user", JSON.stringify(user));
  }
};

//Retourne la date d'expiration du token
export const getExp = () => {
  if (getToken()) {
    return jwt<TokenInterface>(getToken()!).exp;
  } else return null;
};

//Retourne l'ID de l'utilisateur
export const getUserId = () => {
  if (getToken()) {
    return jwt<TokenInterface>(getToken()!).id;
  } else return null;
};

//Retourne les rôles de l'utilisateur
export const getUserRoles = () => {
  if (getToken()) {
    return jwt<TokenInterface>(getToken()!).roles;
  } else return null;
};

/**
 * Fonction permettant de rediriger un utilisateur authentifié
 * @returns redirection ou null
 */
export function redirectIfUserIsLogged() {
  const token = getToken();
  //Si l'utilisateur vérifie son email
  if (window.location.pathname.includes("/email/verify")) {
    return null;
  }
  //Si l'utilisateur est authentifié, redirection sur le dashboard
  if (token) {
    return redirect("/dashboard");
  }
  //la fonction doit avoir une valeur de retour si le token est présent. Ici nous n'avons pas besoin de valeur spécifique, le retour est null
  return null;
}

export const getRole = () => {
  const roles = getUserRoles();
  if (roles !== null) {
    const role =
      roles.find((role) => role === "ROLE_ADMIN") !== undefined
        ? "ROLE_ADMIN"
        : "ROLE_USER";
    return role;
  } else {
    return null;
  }
};

export const removeStorage = () => {
  return sessionStorage.clear();
};

//Retourne si le mode dark est activé dans le session storage
export const getDarkModeEnabled = () => {
  return sessionStorage.getItem("darkModeEnabled") === "true" ? true : false;
  // return sessionStorage.getItem("darkModeEnabled")
};

//Stocke si le mode dark est activé dans le session storage
export const setDarkModeEnabled = (darkMode: any) => {
  return sessionStorage.setItem("darkModeEnabled", darkMode);
};

//Retourne la time zone choisie
export const getTimeZone = () => {
  return sessionStorage.getItem("timeZone");
  // return sessionStorage.getItem("darkModeEnabled")
};

////Stocke la time zone choisie
export const setTimeZone = (timeZone: any) => {
  return sessionStorage.setItem("timeZone", timeZone);
};
