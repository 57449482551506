import React from "react";
import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";

/**
 * Composant
 * page d'erreur d'une route non valide
 * retourne une page d'erreur si l'utilisateur est connecté sinon redirige vers la page de login
 * @return {jsx element}
 */
const ErrorPage = () => {
  // Init react-i18next localisation
  const { t } = useTranslation(["errorPage"]);

  const navigate = useNavigate();

  return (
    <>
      <div className=" ">
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8} className="d-flex flex-column align-items-center">
                <div className="clearfix">
                  <h1 className="display-3">{t("notFound")}</h1>
                  <Divider />
                  <p className="text-medium-emphasis text-center">
                    {t("notFoundPhrase")}
                  </p>
                </div>

                <CButton
                  color="dark"
                  className="px-4 my-2  d-flex align-items-center"
                  onClick={() => navigate("/")}
                >
                  <i className="pi pi-arrow-circle-left pe-3"></i>
                  <span> {t("return")}</span>
                </CButton>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
