import { createContext, useState } from "react";
import jwtDecode from "jwt-decode";
import { TokenInterface } from "src/typesPerso/TokenInterface";
import { clearStorage, getToken } from "src/utils/browserStorage";
import {
  getFirstname,
  getLastname,
  setFirstname,
  setLastname,
  setDarkModeEnabled,
  getDarkModeEnabled,
  setTimeZone,
  getTimeZone,
} from "../utils/helpers";

interface contextInterface {
  token: string | null;
  firstnameContext: string | null;
  lastnameContext: string | null;
  changeFirstname: (firstname: string) => void;
  changeLastname: (lastname: string) => void;
  login: (token: string) => void;
  logout: () => void;
  darkModeEnabledContext: boolean | undefined;
  changeDarkModeEnabled: (value: boolean) => void;
  timeZoneContext: string | undefined | null;
  changeTimeZone: (value: any) => void;
}

export const UserContext = createContext<contextInterface>({
  token: "",
  login: (token) => {},
  firstnameContext: "",
  changeFirstname: () => {},
  lastnameContext: "",
  changeLastname: () => {},
  darkModeEnabledContext: undefined,
  changeDarkModeEnabled: () => {},
  timeZoneContext: undefined,
  changeTimeZone: () => {},
  logout: () => {},
});

//get token from localStorage
const userTokenStorage = getToken();

export const UserContextProvider = (props: any) => {
  const [token, setToken] = useState(userTokenStorage);
  const [firstnameContext, setFirstnameContext] = useState(getFirstname());
  const [lastnameContext, setLastnameContext] = useState(getLastname());
  // const [dashboardVersionContext, setDashboardVersionContext] = useState(
  //   getDashboardVersion()
  // )
  const [darkModeEnabledContext, setDarkModeEnabledContext] = useState(
    getDarkModeEnabled()
  );
  const [timeZoneContext, setTimeZoneContext] = useState(getTimeZone());

  //const [isLoggedIn, setLoggedIn] = useState(getJwt ? true : false)
  const userIsLoggedIn = !!token;

  const handleLogin = (token: any) => {
    //setLoggedIn(true)
    setFirstname(jwtDecode<TokenInterface>(token).firstname);
    setLastname(jwtDecode<TokenInterface>(token).lastname);
    setFirstnameContext(jwtDecode<TokenInterface>(token).firstname);
    setLastnameContext(jwtDecode<TokenInterface>(token).lastname);
    // setDashboardVersion(1)
    // setDashboardVersionContext(1)
    setDarkModeEnabled(false);
    setDarkModeEnabledContext(false);
    setTimeZone("Europe/Paris");
    setTimeZoneContext("Europe/Paris");
    setToken(token);
  };

  const handleLogout = () => {
    setDarkModeEnabled(false);
    setDarkModeEnabledContext(false);
    //setLoggedIn(false)
    setToken(null);
    clearStorage();
    //setFirstnameContext("")
    //setLastnameContext("")
  };

  const handleChangeFirstname = (firstname: string) => {
    setFirstnameContext(firstname);
  };

  const handleChangeLastname = (lastname: string) => {
    setLastnameContext(lastname);
  };

  // const handleChangeDashboardVersion = (version) => {
  //   setDashboardVersionContext(version)
  // }

  const handleChangeDarkModeEnabled = (darkMode: any) => {
    setDarkModeEnabledContext(darkMode);
  };

  const handleChangeTimezone = (timeZone: any) => {
    setTimeZoneContext(timeZone);
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    firstnameContext: firstnameContext,
    lastnameContext: lastnameContext,
    // dashboardVersionContext: dashboardVersionContext,
    darkModeEnabledContext: darkModeEnabledContext,
    timeZoneContext: timeZoneContext,
    changeFirstname: handleChangeFirstname,
    changeLastname: handleChangeLastname,
    //changeDashboardVersion: handleChangeDashboardVersion,
    changeDarkModeEnabled: handleChangeDarkModeEnabled,
    changeTimeZone: handleChangeTimezone,
    login: handleLogin,
    logout: handleLogout,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};
