import { createSlice } from "@reduxjs/toolkit";

const initialbackdropShowState = {
  backdropShow: false,
};

export const backdropShowSlice = createSlice({
  name: "backdropShow",
  initialState: initialbackdropShowState,
  reducers: {
    show: (state, action) => {
      state.backdropShow = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const backdropShowActions = backdropShowSlice.actions;

export default backdropShowSlice.reducer;
