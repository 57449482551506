import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./features/sidebar/sidebarSlice";
import languageSlice, { setLanguage } from "./features/language/languageSlice";
import reloadComponentSlice from "./features/reloadComponent/reloadComponentSlice";
import backdropShowSlice from "./features/backdrop/backdropShowSlice";
import appStateSlice from "./features/appState/appStateSlice";
import i18n from "src/i18n";

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    language: languageSlice,
    reloadComponent: reloadComponentSlice,
    backdropShow: backdropShowSlice,
    appState: appStateSlice,
  },

  //TODO: voir réellement à quoi ça sert et pourquoi
  //C'est pour les routes..... ce n'est pas une variable serializable (elle contient du jsx)
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
});

// Écoutez l'événement 'loaded' de i18next
//dès que i18n a fini de s'initialiser on met à jour la langue
i18n.on("loaded", () => {
  store.dispatch(setLanguage(i18n.language.split("-")[0]));
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
