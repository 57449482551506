import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import notFound from "src/assets/lang/notFound.png";
import { withTranslation } from "react-i18next";
import { setLanguage } from "src/store/features/language/languageSlice";
import fr from "src/assets/lang/fr.png";
import en from "src/assets/lang/uk.png";
import { useDispatch, useSelector } from "react-redux";
import style from "./AppHeaderDropdown.module.css";
import { backdropShowActions } from "src/store/features/backdrop/backdropShowSlice";
import i18n from "src/i18n";
import { RootState } from "src/store/store";

const languagesProps = {
  fr: { image: fr, label: "Français" },
  en: { image: en, label: "English" },
  // Ajoutez d'autres langues ici avec leurs chemins d'accès aux images
};

const AppHeaderDropdownLanguage = () => {
  //Ajout d'un effet de blur au clique sur le dropdown en récupérant l'état du backdrop dans le store
  const dispatch = useDispatch();
  const backdropShow = useSelector(
    (state: RootState) => state.backdropShow.backdropShow
  ); //store du backdrop
  const language = useSelector((state: RootState) => state.language.language); // store du langage

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    dispatch(setLanguage(lng));
  };

  return (
    <CDropdown
      onClick={() => dispatch(backdropShowActions.show(!backdropShow))}
      variant="nav-item"
    >
      <CDropdownToggle className="py-0" caret={false}>
        <CAvatar
          shape="rounded-0"
          src={
            languagesProps[language as keyof typeof languagesProps]?.image
              ? languagesProps[language as keyof typeof languagesProps].image
              : notFound
          }
          className={`${style.shadow} avatar-xs`}
        />
      </CDropdownToggle>
      <CDropdownMenu className="dropdown-menu position-blur">
        {languagesProps ? (
          Object.keys(languagesProps)
            ?.filter((l) => l !== language)
            .map((lang) => {
              return (
                <CDropdownItem
                  key={lang}
                  aria-label={lang}
                  className={style.cursorLink}
                  onClick={() => {
                    setTimeout(() => {
                      handleChangeLanguage(lang);
                    }, 200);
                  }}
                  data-testid={lang}
                >
                  <img
                    height={48}
                    width={48}
                    src={
                      languagesProps[lang as keyof typeof languagesProps].image
                    }
                    alt={lang}
                    className="me-2 avatar-xs"
                    // size="sm"
                    // shape="rounded-0"
                  />
                  {languagesProps[lang as keyof typeof languagesProps].label}
                </CDropdownItem>
              );
            })
        ) : (
          <></>
        )}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default withTranslation()(AppHeaderDropdownLanguage);
