import React from "react";
import { redirectIfUserIsLogged } from "../../helpers";
import { Suspense } from "react";
import Loader from "src/components/loaders/Loader";
import { RouteInterface } from "src/typesPerso/Route";

//Routes publiques
const Login = React.lazy(() => import("src/views/general/public/login/Login"));
const CreateAccount = React.lazy(
  () => import("src/views/general/public/createAccount/CreateAccount")
);
const PasswordResetValidation = React.lazy(
  () =>
    import(
      "src/views/general/public/passwordResetValidation/PasswordResetValidation"
    )
);
const EmailChangeValidation = React.lazy(
  () => import("src/views/general/emailChangeValidation/EmailChangeValidation")
);
const Offline = React.lazy(() => import("src/views/general/offline/Offline"));
const PasswordReset = React.lazy(
  () => import("src/views/general/public/passwordReset/PasswordReset")
);

export const publicRoutes: Array<RouteInterface> = [
  {
    name: "Login",
    index: true,
    element: <Login />,
    //On redirige l'utilisateur s'il est connecté
    loader: redirectIfUserIsLogged,
  },
  {
    name: "Login",
    path: "/login",
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
    loader: redirectIfUserIsLogged,
  },
  {
    name: "Offline",
    path: "/offline",
    element: (
      <Suspense fallback={<Loader />}>
        <Offline />
      </Suspense>
    ),
    loader: redirectIfUserIsLogged,
  },
  {
    name: "Recovery",
    path: "/recovery",
    element: (
      <Suspense fallback={<Loader />}>
        <PasswordReset />
      </Suspense>
    ),
    loader: redirectIfUserIsLogged,
  },
  //Page de login lorsque l'utilisateur a fait une demande de changement d'email et a cliqué sur le lien envoyé sur son ancien email. Si l'utilisateur connecté entre ce path, redirige vers la page de login
  {
    name: "VerifyEmail",
    path: "/email/verify/:token",
    element: (
      <Suspense fallback={<Loader />}>
        <EmailChangeValidation />
      </Suspense>
    ),
    //loader: redirectIfUserIsLogged,
  },
  //Page d'activation du compte pour un utilisateur non connecté qui a recu ce lien par email après avoir été créé par un administrateur
  {
    name: "InitAccount",
    path: "/init/account/:token",
    element: (
      <Suspense fallback={<Loader />}>
        <CreateAccount />
      </Suspense>
    ),
    loader: redirectIfUserIsLogged,
  },
  //Page de modification du mot de passe, lien recu sur l'email de l'utilisateur après qu'il ait fait une demande de récupération de mot de passe
  {
    name: "ResetPassword",
    path: "/reset/password/:token",
    element: (
      <Suspense fallback={<Loader />}>
        <PasswordResetValidation />
      </Suspense>
    ),
    loader: redirectIfUserIsLogged,
  },
];
