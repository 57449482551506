import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSidebar, CSidebarBrand } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import style from "./AppSidebar.module.css";
import { sidebarActions } from "src/store/features/sidebar/sidebarSlice";
import packageInfo from "../../../package.json";
import logo from "src/assets/logo.png";
import SidebarItemCollapse from "./sidebarMenu/SidebarItemCollapse";
import SidebarItem from "./sidebarMenu/SidebarItem";
import SidebarItemCategory from "./sidebarMenu/SidebarItemCategory";
import { RouterContext } from "src/store/routerContext";
import { RootState } from "src/store/store";

const AppSidebar = () => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const sidebarShow = useSelector(
    (state: RootState) => state.sidebar.sidebarShow
  );
  const routes = useContext(RouterContext);

  return (
    <CSidebar
      position="fixed"
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(sidebarActions.show(visible));
      }}
    >
      <CSidebarBrand className="d-md-flex">
        <div className={style.logo}>
          <Link to="/">
            <img className={style.img} src={logo} alt="bookit" />
          </Link>
        </div>
      </CSidebarBrand>
      <div style={{ overflow: "auto", marginTop: "20px" }}>
        {/* PARTIE NAVIGATION */}
        {routes.router!.map((route, index) =>
          route.sidebarProps ? (
            route.sidebarProps.mainMenu ? (
              <SidebarItemCategory item={route} key={index} />
            ) : route.children ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        )}
      </div>
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: "0px",
          backgroundColor: "#3c4b64",
          zIndex: "99",
          width: "100%",
        }}
      >
        <span style={{ fontSize: "0.7rem" }}>
          LS2i &copy; 2023 {t("footer.rights")} - {packageInfo.version}
        </span>
      </div>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
