import jwtDecode from "jwt-decode";
import { getToken } from "../browserStorage";
import { TokenInterface } from "src/typesPerso/TokenInterface";

//Récupération des roles de l'utilisateur authentifié
export const getUserRoles = () => {
  const token = getToken();
  if (token) {
    //décodage du jwt
    const userdatas = jwtDecode<TokenInterface>(token);
    return userdatas.roles;
  }
};
