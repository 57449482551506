import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { UserContext } from "src/store/userContext";
import { appStateActions } from "src/store/features/appState/appStateSlice";

const PageWrapper = (props) => {
  const dispatch = useDispatch();

  //récupération de la variable darkmode
  const { darkModeEnabledContext } = useContext(UserContext); //Contexte application nom et prénom utilisateur si changement des infos via mercure

  useEffect(() => {
    if (props.state) {
      dispatch(appStateActions.set(props.state));
    }
    //const allDiv = document.body.querySelectorAll("*")
    if (darkModeEnabledContext === true) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    // if (darkModeEnabledContext === true) {
    //   //On ajoute la class dark
    //   allDiv.forEach((element) => {
    //     element.classList.add("dark")
    //   })
    // } else {
    //   //On retire la class dark
    //   allDiv.forEach((element) => {
    //     element.classList.remove("dark")
    //   })
  }, [dispatch, props, darkModeEnabledContext]);

  return <>{props.children}</>;
};

export default PageWrapper;
