import React from "react";
import { Suspense } from "react";
import Loader from "src/components/loaders/Loader";
import PageLayout from "./PageLayout";
import CIcon from "@coreui/icons-react";
import {
  cilCloudDownload,
  cilCloudUpload,
  cilUser,
  cilViewQuilt,
} from "@coreui/icons";
import { RouteInterface } from "src/typesPerso/Route";

//Routes utilisateur
const Upload = React.lazy(() => import("src/views/upload/Upload"));
const ExportAbebooks = React.lazy(
  () => import("src/views/exportStock/Abebooks/ExportAbebooks")
);
const ExportAmazonJP = React.lazy(
  () => import("src/views/exportStock/AmazonJP/ExportAmazonJP")
);
const ExportAmazonUS = React.lazy(
  () => import("src/views/exportStock/AmazonUS/ExportAmazonUS")
);
const ExportAmazonCA = React.lazy(
  () => import("src/views/exportStock/AmazonCA/ExportAmazonCA")
);
const ExportRakuten = React.lazy(
  () => import("src/views/exportStock/Rakuten/ExportRakuten")
);
const Profile = React.lazy(() => import("src/views/general/profile/Profile"));

const Dashboard = React.lazy(() => import("src/views/dashboard/Dashboard"));

//Setting page
const Settings = React.lazy(
  () => import("src/views/general/settings/Settings")
);

//Routes admin
const Users = React.lazy(() => import("src/views/admin/users/Users"));

export const routes: Array<RouteInterface> = [
  {
    name: "Profile",
    path: "/profile",
    state: "profile",
    element: (
      <Suspense fallback={<Loader />}>
        <Profile />
      </Suspense>
    ),
    //loader: loaderProfile,
    permissions: ["ROLE_USER"],
  },
  {
    name: "Settings",
    path: "/settings",
    state: "settings",
    element: (
      <Suspense fallback={<Loader />}>
        <Settings />
      </Suspense>
    ),
    permissions: ["ROLE_USER"],
  },
  {
    name: "Dashboard",
    index: true,
    state: "dashboard",
    element: (
      <Suspense fallback={<Loader />}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    state: "dashboard",
    sidebarProps: {
      displayText: "dashboard",
      icon: <CIcon icon={cilViewQuilt} height={25} width={25} />,
      style: "menu1",
    },
    element: (
      <Suspense fallback={<Loader />}>
        <Dashboard />
      </Suspense>
    ),
    //loader: loaderProfile,
    permissions: ["ROLE_USER"],
  },
  {
    name: "StockManagement",
    sidebarProps: {
      displayText: "stocManagement",
      mainMenu: true,
    },
    permissions: ["ROLE_IMPORT", "ROLE_EXPORT"],
  },
  {
    name: "importStock",
    path: "/import-stock",
    state: "import-stock",
    sidebarProps: {
      displayText: "importStock",
      icon: <CIcon icon={cilCloudUpload} height={25} width={25} />,
      style: "menu1",
    },
    element: <PageLayout />,
    permissions: ["ROLE_IMPORT"],

    children: [
      {
        name: "Upload",
        path: "/import-stock/upload",
        state: "import-stock.upload",
        sidebarProps: {
          displayText: "upload",
          style: "menu2",
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Upload />
          </Suspense>
        ),
        permissions: ["ROLE_IMPORT"],
      },
    ],
  },

  {
    name: "exportStock",
    path: "/export-stock",
    state: "export-stock",
    sidebarProps: {
      displayText: "exportStock",
      icon: <CIcon icon={cilCloudDownload} height={25} width={25} />,
      style: "menu1",
    },
    element: <PageLayout />,
    permissions: ["ROLE_EXPORT"],

    children: [
      {
        name: "Export Abebooks",
        path: "/export-stock/abebooks",
        state: "export-stock.abebooks",
        permissions: ["ROLE_EXPORT"],
        sidebarProps: {
          displayText: "downloadAbebooks",
          style: "menu2",
        },
        element: (
          <Suspense fallback={<Loader />}>
            <ExportAbebooks />
          </Suspense>
        ),
      },
      {
        name: "Export AmazonUS",
        path: "/export-stock/amazonus",
        state: "export-stock.amazonus",
        permissions: ["ROLE_EXPORT"],
        sidebarProps: {
          displayText: "downloadAmazonus",
          style: "menu2",
        },
        element: (
          <Suspense fallback={<Loader />}>
            <ExportAmazonUS />
          </Suspense>
        ),
      },
      {
        name: "Export AmazonJP",
        path: "/export-stock/amazonjp",
        state: "export-stock.amazonjp",
        permissions: ["ROLE_EXPORT"],
        sidebarProps: {
          displayText: "downloadAmazonjp",
          style: "menu2",
        },
        element: (
          <Suspense fallback={<Loader />}>
            <ExportAmazonJP />
          </Suspense>
        ),
      },
      {
        name: "Export AmazonCA",
        path: "/export-stock/amazonca",
        state: "export-stock.amazonca",
        permissions: ["ROLE_EXPORT"],
        sidebarProps: {
          displayText: "downloadAmazonca",
          style: "menu2",
        },
        element: (
          <Suspense fallback={<Loader />}>
            <ExportAmazonCA />
          </Suspense>
        ),
      },
      {
        name: "Export Rakuten",
        path: "/export-stock/rakuten",
        state: "export-stock.rakuten",
        permissions: ["ROLE_EXPORT"],
        sidebarProps: {
          displayText: "downloadRakuten",
          style: "menu2",
        },
        element: (
          <Suspense fallback={<Loader />}>
            <ExportRakuten />
          </Suspense>
        ),
      },
    ],
  },
  {
    name: "Admin",
    sidebarProps: {
      displayText: "admin",
      mainMenu: true,
    },
    permissions: ["ROLE_ADMIN"],
  },

  {
    name: "Users",
    path: "/admin/users",
    state: "admin.users",
    sidebarProps: {
      displayText: "users",
      icon: (
        <CIcon
          icon={cilUser}
          height={25}
          width={25}
          style={{ marginRight: "5px" }}
        />
      ),
    },
    element: (
      <Suspense fallback={<Loader />}>
        <Users />
      </Suspense>
    ),
    permissions: ["ROLE_ADMIN"],
  },
];
