import React, { Suspense, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./scss/style.scss";
import { RouterContext } from "./store/routerContext";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Loader from "./components/loaders/Loader";
import PubilcLayout from "./layout/public/PublicLayout";
import DefaultLayout from "./layout/protected/DefaultLayout";
import ErrorPage from "./views/ErrorPage/ErrorPage";
import { getUserRoles } from "./utils/helpers";
import { GenerateRoute } from "./utils/routes/GenerateRoutes";

function App() {
  const version = packageInfo.version;
  const roles = getUserRoles();
  //ROUTER
  //const { router } = useContext(RouterContext);
  const routes = GenerateRoute(useContext(RouterContext).router);

  return (
    <CacheBuster
      currentVersion={version}
      reloadOnDowngrade={true}
      // onCacheClear={() => {
      //   window.location.reload(true);
      // }}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            {!roles ? (
              <Route path="/" element={<PubilcLayout />}>
                {routes}
              </Route>
            ) : (
              <Route path="/" element={<DefaultLayout />}>
                {routes}
              </Route>
            )}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
        {/* <RouterProvider router={router} fallbackElement={<Loader />} /> */}
      </Suspense>
    </CacheBuster>
  );
}

export default App;
