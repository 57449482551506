import { createSlice } from "@reduxjs/toolkit";

const initialappStateState = {
  appState: "",
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState: initialappStateState,
  reducers: {
    set: (state, action) => {
      state.appState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const appStateActions = appStateSlice.actions;

export default appStateSlice.reducer;
