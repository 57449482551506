import { createSlice } from "@reduxjs/toolkit";
import { getSelectedLanguage } from "src/utils/config/language";

const initialState = {
  language: getSelectedLanguage(),
};

export const languageSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
