import { createContext, useState } from "react";
import { getAllowedRoutes } from "src/utils/routes/getAllowedRoutes";
import { routes } from "src/utils/routes/routes";

export const RouterContext = createContext({
  router: getAllowedRoutes(routes),
  handleUpdateRouter: (router: any) => {},
});

export const RouterContextProvider = (props: any) => {
  const [router, setRouter] = useState(getAllowedRoutes(routes));

  const handleUpdateRouter = (router: any) => {
    setRouter(router);
  };

  const contextValue = {
    router: router,
    handleUpdateRouter: handleUpdateRouter,
  };

  return (
    <RouterContext.Provider value={contextValue}>
      {props.children}
    </RouterContext.Provider>
  );
};
